function retiraElementoRepetido() {
	const tags = [...document.querySelectorAll('.wrapper-meus-estados p.teste')];
	const texts = new Set(tags.map(x => x.innerHTML));
	// console.log("meu texto", texts);
	tags.forEach(tag => {
		if(texts.has(tag.innerHTML)){
			texts.delete(tag.innerHTML);
		}
		else{
			tag.remove()
		}
	})
}
retiraElementoRepetido();	

function retiraElementoRepetidoSobre() {
	const tags = [...document.querySelectorAll('.wrapper-meus-estados-sobre p.teste')];
	const texts = new Set(tags.map(x => x.innerHTML));
	// console.log("meu texto", texts);
	tags.forEach(tag => {
		if(texts.has(tag.innerHTML)){
			texts.delete(tag.innerHTML);
		}
		else{
			tag.remove()
		}
	})
}
retiraElementoRepetidoSobre();

window.addEventListener('load', function() {
    /**
     * Setup do token de validação no cabeçalho
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

/**
 * Objeto público com  métodos usados ao longo do app
 */

var APP = {

    goToElement: function(el) {
        $("html, body").animate({
            scrollTop: (($(window).width() > 960) ? $(el).offset().top : $(el).offset().top - 40)
        }, 600);
	},
    
    chamaMenu: function(){
        
			$('header').toggleClass('menu-mobile-open');
			$('body').toggleClass('mask-menu');

            if( $('header').hasClass('menu-mobile-open') ){
				$('html').css('overflow-y', 'hidden');
            }else{
                $('html').css('overflow-y', 'inherit');
            }
        
	},

	validaCampoEmail: function(element) {
		var value = element.value;
		var regex = /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/;

        if (regex.test(value)) {
            $(element).removeClass('error');
            return;
        } else {
            element.value = '';
            element.placeholder = 'E.x: exemplo@exemplo.com';
            $(element).addClass('error');
            Swal.fire("E-mail informado incorretamente!","","error");
        }
    },

    validaForm: function(form){
		var _this = this;

		var required = form.find('.required');
		var select = form.find('select.required');
		var requiredSelect = form.find('select.required');
		var aceite = form.find('.aceite');
		var requiredAceite = form.find('.aceite .required:checked');
		var req = 0;
		var reqSel = 0;
		var reqAc = 0;
		var classe;

		for(i=0;i<required.length;i++){
			if($(required[i]).val() == "" && $(required[i]).prop('disabled') != true){
				classe = $(required[i]).attr('class');
				
				if(classe.search("required") != -1){	
					$(required[i]).addClass('error');
				}
				req = 1;
			}else{
				classe = $(required[i]).attr('class');
				
				if(classe.search("error") != -1){
					$(required[i]).removeClass('error');
				}
			}
		}

		if(select.length > 0){
			for(i=0;i<requiredSelect.length;i++){
				if($(requiredSelect[i]).find(":selected").text() == "Selecione"){
					classe = $(requiredSelect[i]).attr('class');
					
					if(classe.search("required") != -1){	
						$(requiredSelect[i]).addClass('error');
					}
					reqSel = 1;
				}else{
					classe = $(requiredSelect[i]).attr('class');
					
					if(classe.search("error") != -1){
						$(requiredSelect[i]).removeClass('error');
					}
				}
			}
		}

		if(aceite.length > 0){
			_this.projetoAceite.removeClass('error');
			if(requiredAceite.length == 0){
				_this.projetoAceite.addClass('error');
				reqAc = 1;
			}else{
				_this.projetoAceite.removeClass('error');
			}
		}
		
		if(req == 1 || reqSel == 1 || reqAc == 1){
			return false;
		}else if(req == 0 && reqSel == 0 && reqAc == 0){
			return true;
		}
	},

	ativarContador: function(){
		$('.counter').each(function() {
			var $this = $(this),
			countTo = $this.attr('data-count');
			$({ countNum: $this.text()}).animate({
				countNum: countTo
			},
			{
				duration: 1000,
				easing:'linear',
				step: function() {
					$this.text(Math.floor(this.countNum));
				},
				complete: function() {
					$this.text(this.countNum);
				}
			});
		});
	},

    enviarForm: function(id, route){

		if(this.validaForm($('#'+id))){
			$('#btn-enviar-contato').text('Enviando');
			var dados = $("#"+id).serialize();
			jQuery.ajax({
				type: "POST",
				url: base_url + '/' + route,
				data: dados,
				success: function(msg) {
					if (msg == 'success'){
						document.getElementById(id).reset();
						$('#btn-enviar-contato').text('Enviar');
						Swal.fire("Enviado com sucesso","","success");
					} else if (msg == 'cpf_cnpj') {
						$('#btn-enviar-contato').text('Enviar');
                        Swal.fire("Informe um CNPJ ou um CPF!","","error");
					} else {
						$('#btn-enviar-contato').text('Enviar');
                        Swal.fire("Ocorreu algum problema temporário, tente novamente mais tarde.","","error");
					} 
				}
			});
		}else{
			$('#btn-enviar-contato').text('Enviar');
            Swal.fire("Algum campo ficou em branco, por favor reveja e tente novamente","","warning");
		}
	},

	filtrarDistribuidoras: function(estado,pagina) {
		if (estado) {			
			document.location.href = base_url+"/"+pagina+"/"+estado;
			return;
		} else {
			location.reload();
		}
	}
};

window.addEventListener('load', function(){
	const tags = [...document.querySelectorAll('.wrapper-meus-estados p.teste')];
	let states = tags.map(x => x.innerHTML);

	for (let i = 0; i < states.length; i++) {
		const element = document.getElementById(states[i]);
	
		element.classList.add('default');
		element.addEventListener('click', function() {
			APP.filtrarDistribuidoras(this.id,'distribuidores');
		})
	}
})

window.addEventListener('load', function(){
	const tags = [...document.querySelectorAll('.wrapper-meus-estados-sobre p.teste')];
	let states = tags.map(x => x.innerHTML);

	for (let i = 0; i < states.length; i++) {
		const element = document.getElementById(states[i]);
	
		element.classList.add('default');
		element.addEventListener('click', function() {
			APP.filtrarDistribuidoras(this.id,'sobre');
		})
	}
})

//Animação sem precisar rolar
$('.bottom-to-fade-onload').addClass('bottom-to-fade-anim');
$('.left-to-fade-onload').addClass('left-to-fade-anim');
$('#mapa-distribuidoras').addClass('animate');

//Máscaras dos formulários
$(".mask-cnpj").mask("99.999.999/9999-99");
$(".mask-phone").mask("(99) 99999-9999");
$(".mask-cpf").mask("999.999.999-99");

$("#email").blur(function() {
    var value = $("#email").val();
    var regex = /^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/;

    if (regex.test(value)) {
        $("#email").removeClass('error');
        return;
    } else {
        $("#email").val('');
        $("#email").addClass('error');
        Swal.fire("E-mail informado incorretamente!","","error");
    } 
});

window.addEventListener('scroll', function eventScroll(){
	const windowTop = window.pageYOffset + ((window.innerHeight * 3) / 4);
	
	if($(".wrapper-animation").length){
		var targetSection = $(".wrapper-animation");
	}else if ($(".wrapper-animation").length){
		var targetSection = $("#wrapper-wiperX");
	}else if ($("#mapa-sobre").length) {
		var targetSection = $("#area-atuacao");
	} else {
		targetSection = $("body");
	}

	if ($("#counter").length) {

		if($("#counter") && windowTop > $("#counter").offset().top) {
			this.setTimeout(function() {
				APP.ativarContador();
			}, 500); 
		}
	}
	
	if(targetSection && windowTop > targetSection.offset().top) {
		$(".elements-to-animate").addClass('animate');
	} 
});

//expondo projeto padrao
window.APP = APP;

/**
 * Páginas
 */
//require('./uteis');